<template>
  <div class="baikal-attraction__slider">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(slide, i) in slides" :key="i">
        <div class="baikal-attraction__slide" :style="getStyles(slide)"></div>
      </swiper-slide>

      <div class="app-swiper-button-prev" slot="button-prev"></div>
      <div class="app-swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "BaikalSlider",
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: false,
        // fadeEffect: { crossFade: true },
        // speed: 1500,
        loop: true,
        effect: "fade",
        navigation: {
          nextEl: ".app-swiper-button-next",
          prevEl: ".app-swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(this.randomIntFromInterval(1, 4), 1000, false);
    // this.swiper.autoplay.stop();
    // this.swiper.autoplay.start();
  },
  methods: {
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    getStyles(slide) {
      return {
        background: "#ffffff url(" + slide.img + ") no-repeat center center",
        "background-size": "cover",
      };
    },
  },
};
</script>
