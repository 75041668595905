<template>
  <div>
    <BaikalAttraction :slides="getSlides(1)">
      <h2 class="baikal-attraction__title">Иркутская область</h2>
      <p class="baikal-attraction__text">
        Область представляет собой уникальное сочетание природного потенциала и
        культурно-исторических мест и памятников различных периодов: первобытные
        стоянки, эпоха декабристов и ударные советские стройки.
      </p>
      <p class="baikal-attraction__text">
        Среди уроженцев области немало знаменитостей: драматург А.В.Вампилов,
        режиссёр Л.И.Гайдай, поэт Е.А.Евтушенко, пианист Д.Л.Мацуев, артист
        балета Р.Х.Нуреев, писатель В.Г.Распутин, режиссер М.И.Ромм.
      </p>
      <p class="baikal-attraction__text">
        Озеро Байкал с его прибрежной зоной, горные ландшафты и многочисленные
        водоемы составляют основу туристского потенциала Иркутской области и с
        каждым годом привлекают сюда все больше туристов со всего мира.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(2)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Иркутск</h2>
      <p class="baikal-attraction__text">
        Культурный и исторический центр области. Один из красивейших городов
        России, сочетающий уникальный архитектурный стиль и традиции культуры
        сибирского города.
      </p>
      <p class="baikal-attraction__text">
        «130 квартал» считается культурно-историческим центром Иркутска. Это
        восстановленная историческая часть города: отреставрированные деревянные
        дома, имеющие историческую ценность, гончарные мастерские, сувенирные
        лавки. Недалеко находится Дом музыки им. Д.Мацуева, всемирно известного
        пианиста - иркутянина.
      </p>
      <p class="baikal-attraction__text">
        Также интересны историко-мемориальный комплекс «Декабристы в Иркутске»,
        музей истории города, музей-усадьба им. В.П. Сукачева, художественные
        музеи и картинные галереи.
      </p>
      <p class="baikal-attraction__text">
        Многочисленные театры снискали любовь не только местных жителей, но и
        туристов со всей страны.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(3)">
      <h2 class="baikal-attraction__title">Волшебный остров Ольхон</h2>
      <p class="baikal-attraction__text">
        Самый крупный остров на Байкале и одно из самых загадочных мест
        Иркутской области. От материка он отделен проливами Ольхонские Ворота и
        Малое море. Кстати, Ольхон - третий по размерам озерный остров в мире!
      </p>
      <p class="baikal-attraction__text">
        Ольхон - по истине не только туристический, но и духовный центр:
        бурятские шаманы считают его центром шаманизма. Из года в год здесь
        собираются шаманы со всей Юго-Восточной и Средней Азии.
      </p>
      <p class="baikal-attraction__text">
        Путешествуя по острову, обязательно нужно посетить самую северную точку
        - мыс Хобой, полюбоваться озером Шара-Нур с соленой минеральной водой и
        лечебной грязью и сделать фото на фоне самого известного символа Байкала
        - Шаман-скалы на мысе Бурхан.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(4)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Заповедные места области</h2>
      <p class="baikal-attraction__text">
        В заповедных местах области обитает множество видов животных, птиц,
        встречаются редкие реликтовые и эндемичные виды растений. Десятки
        представителей флоры и фауны занесены в Красную книгу.
      </p>
      <p class="baikal-attraction__text">
        Среди самых крупных Прибайкальский национальный парк, который охватывает
        территории трех районов области, склоны Приморского хребта, большую
        часть акватории и почти все западное побережье Байкала и Байкало-Ленский
        заповедник, расположенный на территории Качугского и Ольхонского районов
        области. Тофаларский заказник в Нижнеудинском районе и Витимский
        заповедник в долине реки Витим – это первозданная сибирская природа и
        представители животного мира в естественной среде обитания.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(5)">
      <h2 class="baikal-attraction__title">Горные системы</h2>
      <p class="baikal-attraction__text">
        Хамар-Дабан – это горный хребет на юге Восточной Сибири, расположенный и
        на территории Бурятии, и в Иркутской области. Пик Черского - наиболее
        популярный и доступный для туристов объект. Подъем туда безопасен и не
        требует специального снаряжения. У подножия горы находится живописное
        озеро Сердце.
      </p>
      <p class="baikal-attraction__text">
        Один из наиболее популярных и востребованных горнолыжных курортов
        Иркутской области - «Соболиная Гора» расположен недалеко от города
        Байкальск. Близость Байкала обеспечивает ему мягкий климат даже зимой.
        На западном побережье Байкала находится объект притяжения любителей
        древней культуры и искусства народов Восточной Сибири - скала Саган-Заба
        с наскальными рисунками (петроглифами).
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(6)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Водные богатства области</h2>
      <p class="baikal-attraction__text">
        Область славится курортными ресурсами, особенно лечебно-минеральными
        водами, организован их промышленный разлив.
      </p>
      <p class="baikal-attraction__text">
        Речная сеть Иркутской области представлена бассейнами рек Ангара, Лена,
        Нижняя Тунгуска и их многочисленными притоками. На Ангаре построено 4
        водохранилища для ГЭС.
      </p>
      <p class="baikal-attraction__text">
        Минеральная вода из родников горы Весёлой, что недалеко от села
        Худякова, содержит кремниевую кислоту, которая выводит из организма
        вредные вещества и укрепляет иммунитет.
      </p>
      <p class="baikal-attraction__text">
        Недалеко от с. Выдрино раскинулись живописные озёра: Изумрудное,
        Сказочное и Тёплое – любимые места отдыха местных туристов.
      </p>
      <p class="baikal-attraction__text">
        Самый необычный и романтичный водоем области – маленькое озеро в форме
        сердца на хребте Хамар-Дабан у самой границы с Бурятией.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(7)">
      <h2 class="baikal-attraction__title">Кругобайкальская железная дорога</h2>
      <p class="baikal-attraction__text">
        Байкал настолько велик, что для поездок вокруг него была создана
        специальная железная дорога длиной 260 км.
      </p>
      <p class="baikal-attraction__text">
        “Кругобайкалка” сегодня - это не только потрясающие виды на озеро
        Байкал, но и огромное количество уникальных инженерных сооружений начала
        XX века: длинных туннелей, множество каменных и железобетонных галерей,
        мостов, виадуков.
      </p>
      <p class="baikal-attraction__text">
        Участок от города Слюдянка до посёлка Байкал в 89 км – один из самых
        сложных и считается великолепным образцом инженерного искусства. Эта
        ветка эксплуатируется по сей день и очень привлекательна для туристов,
        по ней курсируют регулярные туристические маршруты (рельсовый автобус и
        электропоезд с паровой тягой.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(8)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Поселок Листвянка</h2>
      <p class="baikal-attraction__text">
        Это довольно популярное среди туристов место, расположенное в 70 км от
        Иркутска. Поселок расположен у истока Ангары, единственной реки,
        вытекающей из Байкала!
      </p>
      <p class="baikal-attraction__text">
        Развитая инфраструктура этого места (многочисленные кафе и гостиницы),
        возможность сходить в нерпинарий и своими глазами увидеть байкальских
        нерп, посетить Байкальский музей и совершить прогулку по Байкалу на
        катере и лицезреть сакральный Шаман-камень – все это привлекает сюда
        тысячи туристов из года в год.
      </p>
      <p class="baikal-attraction__text">
        А чего стоит великолепный вид на Байкал и исток Ангары, горы Хамар-Дабан
        со смотровой площадки!
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(9)">
      <h2 class="baikal-attraction__title">
        Культурные и исторические памятники
      </h2>
      <p class="baikal-attraction__text">
        Иркутская земля привлекательна для любителей истории, архитектуры и
        мистики. Здесь сохранилось множество памятников древности и старины, на
        которые может полюбоваться любой желающий.
      </p>
      <p class="baikal-attraction__text">
        В 1887 году в центре Иркутска была сделана уникальная находка: следы
        стоянок и массовых захоронений эпох верхнего палеолита и неолита,
        которую назвали Глазковский некрополь.
      </p>
      <p class="baikal-attraction__text">
        Среди гостей области популярны архитектурно-этнографический музей
        «Тальцы», недалеко от одноименного поселка, включающий комплекс строений
        17-20 вв., а также «Ангарская деревня» на окраине Братска – экспозиция
        демонстрирует традиционную деревню конца 19 - начала 20 в. под открытым
        небом.
      </p>
    </BaikalAttraction>
  </div>
</template>

<script>
import BaikalAttraction from "@/components/partials/BaikalAttraction.vue";

export default {
  name: "BaikalAttractionsZab",
  components: { BaikalAttraction },
  data() {
    return {
      slides: [
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-100.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-101.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-102.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-103.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-104.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-105.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-106.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-107.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-108.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-109.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-110.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/2/baikal-111.jpg"),
        },

        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-200.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-201.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-202.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-203.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-204.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-205.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-206.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-207.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-208.jpg"),
        },
        // {
        //   sectionId: 2,
        //   img: require("@/assets/img/attractions/2/baikal-209.jpg"),
        // },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-210.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-211.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-212.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-213.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/2/baikal-214.jpg"),
        },
        // {
        //   sectionId: 2,
        //   img: require("@/assets/img/attractions/2/baikal-215.jpg"),
        // },

        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-300.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-301.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-302.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-303.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-304.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-305.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-306.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-307.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-308.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-309.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/2/baikal-310.jpg"),
        },

        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-400.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-401.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-402.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-403.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-404.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-405.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-406.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-407.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-408.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-409.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-410.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-411.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/2/baikal-412.jpg"),
        },

        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-500.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-501.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-502.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-503.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-504.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-505.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-506.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-507.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-508.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/2/baikal-509.jpg"),
        },

        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-600.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-601.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-602.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-603.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-604.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-605.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-606.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-607.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-608.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-609.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-610.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/2/baikal-611.jpg"),
        },

        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-700.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-701.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-702.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-703.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-704.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-705.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-706.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-707.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-708.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/2/baikal-709.jpg"),
        },

        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-800.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-801.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-802.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-803.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-804.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-805.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-806.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-807.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-808.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-809.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/2/baikal-810.jpg"),
        },

        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-900.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-901.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-902.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-903.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-904.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-905.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-906.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/2/baikal-907.jpg"),
        },
      ],
    };
  },
  methods: {
    getSlides(sectionId) {
      return this.slides.filter((slide) => slide.sectionId === sectionId);
    },
  },
};
</script>
