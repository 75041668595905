<template>
  <div class="baikal">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <h1 class="page-title">Байкальский регион</h1>

      <div class="page-top__row">
        <div class="page-top__col">
          <!-- <div class="page-top__img"></div> -->
        </div>
        <div class="page-top__col">
          <p class="page-top__text mt-0">
            Байкальский регион расположился на юго-востоке России в пределах
            пояса гор Южной Сибири и северной части Монголии. Он включает
            окружающие озеро Байкал территории Иркутской области, Республики
            Бурятии, а также Забайкальский край.
          </p>
          <p class="page-top__text">
            Озеро Байкал - это территория Всемирного природного наследия.
            Огромное по своей величине, удивительное по красоте и уникальности.
            Здесь сосредоточено около 1/5 мировых запасов пресной воды и более
            4/5 запасов России. Традиционно ученые определяют возраст Байкала в
            25-35 млн. лет.
          </p>
          <p class="page-top__text">
            Байкальский регион с его уникальной природой, разнообразным животным
            и растительным миром, богатой культурой и историей - одно из самых
            притягательных мест для туристов не только России, но и других
            стран.
          </p>
        </div>
      </div>

      <BaikalTabs @input="setActiveTab" />
    </Page>

    <component :is="component" />
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";
import BaikalAttractions from "@/components/partials/BaikalAttractions.vue";
import BaikalAttractionsIrk from "@/components/partials/BaikalAttractionsIrk.vue";
import BaikalAttractionsZab from "@/components/partials/BaikalAttractionsZab.vue";
import BaikalTabs from "@/components/partials/BaikalTabs.vue";

export default {
  name: "Baikal",
  components: {
    Page,
    BaikalAttractions,
    BaikalAttractionsIrk,
    BaikalAttractionsZab,
    BaikalTabs,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  computed: {
    component() {
      switch (this.activeTab) {
        case 1:
          return BaikalAttractions;
        case 2:
          return BaikalAttractionsIrk;
        case 3:
          return BaikalAttractionsZab;
      }
      return "";
    },
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    },
  },
};
</script>
