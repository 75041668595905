<template>
  <div>
    <BaikalAttraction :slides="getSlides(1)">
      <h2 class="baikal-attraction__title">Республика Бурятия</h2>
      <p class="baikal-attraction__text">
        Республика Бурятия - это уникальное сочетание исторических традиций,
        самобытной культуры и первозданной природы. Несмотря на резко
        континентальный климат региона, 300 дней в году здесь светит яркое
        солнце, за это Бурятию еще называют “солнечной республикой”. А
        удивительные ландшафты Тункинского и Окинского районов многим туристам
        напоминают швейцарские Альпы.
      </p>
      <p class="baikal-attraction__text">
        Многих влечет сюда желание увидеть самое древнее, глубокое и красивое
        озеро и принять на его чистых песчаных пляжах солнечные ванны. Других
        манят места, где когда-то проходили воины Чингисхана и сохранились
        древние артефакты гуннской цивилизации. Каждый турист обязательно найдет
        здесь для себя что-то интересное!
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(2)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Религии в Бурятии</h2>
      <p class="baikal-attraction__text">
        В республике мирно соседствуют различные религии и вероисповедания.
      </p>
      <p class="baikal-attraction__text">
        Традиционная религия бурят - это буддизм, в республике более 20 дацанов
        относятся к Буддийской традиционной Сангхе России.
      </p>
      <p class="baikal-attraction__text">
        Православие в Бурятии представлено Русской православной церковью,
        которая включает более 70 организаций.
      </p>
      <p class="baikal-attraction__text">
        Также в республике распространен шаманизм - древняя религия, в основе
        которой вера общения шамана с духами, в Улан-Удэ находится Местная
        религиозная организация шаманов «Тэнгэри».
      </p>
      <p class="baikal-attraction__text">
        Более 250 лет в Бурятии существует уникальная община старообрядцев (или
        семейских), которые до сегодняшнего дня сохранили свою культуру и
        традиции (село Тарбагатай и Десятниково).
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(3)">
      <h2 class="baikal-attraction__title">
        Горячие, термальные и питьевые источники
      </h2>
      <p class="baikal-attraction__text">
        Богата земля бурятская целебными водами! Минеральные питьевые источники
        курорта Аршан в Тункинском районе помогают при болезнях пищеварения,
        дыхания и сердца.
      </p>
      <p class="baikal-attraction__text">
        Любителям принять лечебные ванны под открытым небом будут интересны
        термально-грязевый источник Кучигер в Курумканском районе, который лечит
        в первую очередь недуги костно-мышечной системы, а также бассейн с
        целебной водой из источника геотермальных вод (с температурой до 70°) в
        поселке Ильинка.
      </p>
      <p class="baikal-attraction__text">
        Минеральные воды Шумака в «Долине ста источников» аналогичны водам
        Пятигорска, Кисловодска и Белокурихи. А болезни опорно-двигательной
        системы и радикулит отлично лечатся термальными водами источника бухты
        Змеевая в Чивыркуйском заливе.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(4)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">
        Туристическая зона «Байкальская гавань»
      </h2>
      <p class="baikal-attraction__text">
        В с. Турка Прибайкальского района функционирует Особая экономическая
        зона туристско-рекреационного типа «Байкальская гавань». В структуру
        комплекса входят 4 туристских кластера — туристско-рекреационный кластер
        «Подлеморье», автотуристский кластер «Байкальский», автотуристский
        кластер «Кяхта», автотуристский кластер «Тункинская долина», 8 зон
        экономического благоприятствования туристско-рекреационного типа.
      </p>
      <p class="baikal-attraction__text">
        Байкальская гавань позиционируется как центр туризма на востоке России.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(5)">
      <h2 class="baikal-attraction__title">Заповедники и национальные парки</h2>
      <p class="baikal-attraction__text">
        Если вы хотите насладиться первозданной байкальской природой в сочетании
        с разнообразием ландшафтов, добро пожаловать в Забайкальский
        национальный парк и Баргузинский природный заповедник – старейший в
        России!
      </p>
      <p class="baikal-attraction__text">
        А недалеко от Северобайкальска расположен Фролихинский природный
        заказник с красивейшим горным озером Фролиха, в окружении величественных
        гор.
      </p>
      <p class="baikal-attraction__text">
        Голубые озера, горные и равнинные реки, минеральные источники, мир диких
        животных в естественной среде обитания – все это Тункинский национальный
        парк.
      </p>
      <p class="baikal-attraction__text">
        В верховьях реки Баргузин в труднодоступном районе на севере Бурятии
        расположился Джергинский заповедник, среди коренных жителей которого
        лисицы, соболь, ласка, колонок, выдра, рысь, лось, изюбр, косули.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(6)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Национальная бурятская кухня</h2>
      <p class="baikal-attraction__text">
        Бурятская кухня проста и очень калорийна. Это связано с холодным
        климатом, в котором исконно проживали буряты и, чем жирнее была пища,
        тем дольше кочевники оставались сытыми. Основу составляют мясные
        (конина, баранина и говядина) и молочные продукты.
      </p>
      <p class="baikal-attraction__text">
        Буузы (маленькие юрты из теста с начинкой из рубленного мяса), шулэн
        (наваристый суп домашней лапшой) и жареные хуушуры (мясные пирожки) -
        всегда вызывают восторг у детей и взрослых.
      </p>
      <p class="baikal-attraction__text">
        В праздники на стол обязательно ставят саламат – мучную кашу из жирной
        сметаны и муки. А на десерт часто подают боовы со сгущенкой или сахарной
        пудрой и, конечно, традиционный чай с молоком!
      </p>
      <p class="baikal-attraction__text">
        Бурятская кухня никого не оставляет равнодушным!
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(7)">
      <h2 class="baikal-attraction__title">Горные системы</h2>
      <p class="baikal-attraction__text">
        Горные массивы Бурятии традиционно привлекают любителей активного отдыха
        со всей России.
      </p>
      <p class="baikal-attraction__text">
        Одних притягивает гора Мунку-Сардык — наивысшая точка Восточно-Саянских
        гор, самая высочайшая точка Республики Бурятия (3491 м). Другие
        стремятся попасть на Пик Любви - одну из вершин Тункинских Гольцов
        высотой — 2124 м, расположенных возле прославленного курорта Аршан у
        подножья хребта Восточный Саян.
      </p>
      <p class="baikal-attraction__text">
        Любителей фрирайда, катания на сноубордах, горных лыжах и снегоходах
        влечет гора Мамай в Кабанском районе.
      </p>
      <p class="baikal-attraction__text">
        А с вершины священной для буддистов Бурятии горы «Спящий Лев»
        открывается красивейший вид на Селенгу, самую большую реку, впадающую в
        Байкал.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(8)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Байкальское побережье и острова</h2>
      <p class="baikal-attraction__text">
        Считается, что самые уютные песчаные пляжи на восточном побережье
        Байкала расположены в Максимихе Баргузинского района. Поклонники этой
        местности называют пляжную зону «золотыми песками».
      </p>
      <p class="baikal-attraction__text">
        Но одними из самых привлекательных и заманчивых мест всегда были и
        остаются Чивыркуйский залив и полуостров Святой Нос. Узкий и длинный, с
        песчаными берегами залив привлекает любителей тихого отдыха (рыбалки,
        сбора ягод и грибов). А того, кто решится пройти «Тропу испытаний» и
        покорить главную горную вершину полуострова ждет заслуженная награда:
        удивительные по красоте виды с высоты 1877 м. А там и недалеко до
        Ушканьих островов - известного лежбища байкальского эндемика - нерпы.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(9)">
      <h2 class="baikal-attraction__title">Культура Бурятии</h2>
      <p class="baikal-attraction__text">
        Культурная жизнь республики представлена множеством организаций. Театр
        оперы и балета в Улан-Удэ (кстати, единственный оперный театр в
        Восточной Сибири и на Дальнем Востоке) и легендарный театр «Байкал» -
        победитель телепроекта «Танцуют все!» в 2017 году – популярны не только
        среди местных и туристов, но и успешно гастролируют по всей стране.
      </p>
      <p class="baikal-attraction__text">
        Богатая история региона составляет основу музейного дела. Интересом
        пользуются Кяхтинский краеведческий музей (старейший в Сибири и на
        Дальнем Востоке), Национальный музей РБ - единственный в мире, который
        специализируется на хранении, изучении и популяризации истории и
        предметов искусства, а узнать о быте и обычаях коренных народов
        Забайкалья можно в Этнографическом музее под открытым небом.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(10)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Интересные места и памятники</h2>

      <p class="baikal-attraction__text">
        В Бурятии есть немало мест, где стоит побывать туристу и обязательно
        сделать памятное фото. Например, «Царские ворота» в центре Улан-Удэ -
        копия арки, которую установили в честь посещения Верхнеудинска в 1891
        году цесаревичем Н.Романовым или самая большая скульптура головы Ленина
        в мире, высотой 7,7 метра.
      </p>
      <p class="baikal-attraction__text">
        По дороге на Байкал по традиции нужно сделать обязательную остановку у
        памятного знака хозяину воды «Усан-Лопсон» - верховному повелителю вод в
        бурятской мифологии.
      </p>
      <p class="baikal-attraction__text">
        Завораживают туристов статуя Сандалового Будды в Эгитуйском дацане и
        наскальное 33-метровое изображение Будды Шакьямуни на горе Баян-Хонгор в
        Хоринском районе, а также красоты Баргузинской долины - Ининский сад
        камней и Сувинская саксония.
      </p>
    </BaikalAttraction>
  </div>
</template>

<script>
import BaikalAttraction from "@/components/partials/BaikalAttraction.vue";

export default {
  name: "BaikalAttractions",
  components: { BaikalAttraction },
  data() {
    return {
      slides: [
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-110.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-111.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-112.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-113.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-114.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-115.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-116.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-117.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-118.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-119.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/1/baikal-120.jpg"),
        },

        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-200.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-201.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-202.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-203.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-204.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-205.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-206.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-207.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/1/baikal-208.jpg"),
        },

        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-300.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-301.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-302.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-303.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-304.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-305.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/1/baikal-306.jpg"),
        },

        {
          sectionId: 4,
          img: require("@/assets/img/attractions/1/baikal-400.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/1/baikal-401.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/1/baikal-402.jpg"),
        },

        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-500.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-501.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-502.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-503.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-504.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-505.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-506.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/1/baikal-507.jpg"),
        },

        {
          sectionId: 6,
          img: require("@/assets/img/attractions/1/baikal-600.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/1/baikal-601.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/1/baikal-602.jpg"),
        },

        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-700.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-701.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-702.jpg"),
        },
        // {
        //   sectionId: 7,
        //   img: require("@/assets/img/attractions/1/baikal-703.jpg"),
        // },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-704.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-705.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-706.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-707.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/1/baikal-708.jpg"),
        },

        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-800.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-801.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-802.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-803.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-804.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-805.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-806.jpg"),
        },
        {
          sectionId: 8,
          img: require("@/assets/img/attractions/1/baikal-807.jpg"),
        },

        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-900.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-901.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-902.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-903.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-904.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-905.jpg"),
        },
        {
          sectionId: 9,
          img: require("@/assets/img/attractions/1/baikal-906.jpg"),
        },

        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1000.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1001.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1002.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1003.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1004.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1005.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1006.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1007.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1008.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1009.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1010.jpg"),
        },
        {
          sectionId: 10,
          img: require("@/assets/img/attractions/1/baikal-1011.jpg"),
        },
      ],
    };
  },
  methods: {
    getSlides(sectionId) {
      return this.slides.filter((slide) => slide.sectionId === sectionId);
    },
  },
};
</script>
