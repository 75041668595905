<template>
  <div>
    <div class="baikal-attraction" :class="className">
      <BaikalSlider :slides="slides" />
      <div class="baikal-attraction__descr">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import BaikalSlider from "@/components/partials/BaikalSlider.vue";

export default {
  name: "BaikalAttraction",
  components: { BaikalSlider },
  props: {
    className: String,
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>
