<template>
  <div class="baikal-tabs">
    <label class="baikal-tab">
      <input type="radio" name="region" :value="1" v-model="activeTabVal" />
      <span class="checkmark">Республика Бурятия</span>
    </label>

    <label class="baikal-tab">
      <input type="radio" name="region" :value="2" v-model="activeTabVal" />
      <span class="checkmark">Иркутская область</span>
    </label>

    <label class="baikal-tab">
      <input type="radio" name="region" :value="3" v-model="activeTabVal" />
      <span class="checkmark">Забайкальский край</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaikalTabs",
  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    activeTabVal: {
      get() {
        return this.activeTab;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
