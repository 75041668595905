<template>
  <div>
    <BaikalAttraction :slides="getSlides(1)">
      <h2 class="baikal-attraction__title">Забайкальский край</h2>
      <p class="baikal-attraction__text">
        Уникальное географическое расположение региона, на границе с Монголией и
        Китаем, архитектурные и археологические памятники времен Чингисхана,
        который, по преданию, родился именно в этих землях, общины старообрядцев
        и поселения казаков, священные и сакральные места, окутанные легендами и
        тайнами – это Забайкалье, где удивительным образом соседствуют культура
        и история разных эпох и народов. Это территория невероятного природного
        разнообразия: дикая тайга и бескрайние степи, целебные источники,
        плиточные могилы, скальные останцы, пещеры и наскальные рисунки, и даже
        потухшие вулканы!
      </p>
      <p class="baikal-attraction__text">
        В Забайкалье органично соединились природная красота, национальный и
        культурный колорит!
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(2)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Столица Забайкалья</h2>
      <p class="baikal-attraction__text">
        Чита сочетает в себе традиции коренных народов Сибири, культуру и дух
        казаков-первопроходцев и ссыльных декабристов, поэтому город до сих пор
        хранит богатую и насыщенную историю. Столица Забайкалья – настоящий
        памятник дореволюционной провинциальной архитектуры. Здесь можно увидеть
        и роскошные каменные купеческие дома, и резные деревянные терема, и
        более поздние здания в стиле модерн. Приковывают взгляды туристов
        Шумовский дворец, купеческие доходные дома, особняки и усадьбы.
        Любителей фауны заинтересуют многочисленные коллекции Забайкальского
        ботанического сада.
      </p>
      <p class="baikal-attraction__text">
        Со смотровой площадки на Титовской сопке, словно на ладони, виден весь
        город. Кстати, эта сопка на самом деле конус древнего вулкана, потухшего
        много тысяч лет назад.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(3)">
      <h2 class="baikal-attraction__title">
        Культурные и исторические памятники
      </h2>
      <p class="baikal-attraction__text">
        Культурно-историческое наследие Забайкалья представлено многочисленными
        объектами: Забайкальский краеведческий музей; Музей декабристов в здании
        бывшей Михайло-Архангельской церкви; Бутинский дворец в Нерчинске;
        Успенская церковь в Калинино 1712 г. постройки; Кафедральный Казанский
        собор, выполненный в стиле традиционного церковного зодчества XIV-XVI
        вв.; действующая Читинская соборная мечеть; многочисленные дацаны; музей
        истории казачества в с. Знаменка; минералогический и палеонтологический
        музей в Краснокаменске; дом-усадьба крестьянина семейского в с.
        Архангельском, где в аутентичной атмосфере можно ознакомиться с
        культурой и бытом старообрядцев; Петровский некрополь декабристов в г.
        Петровск-Забайкальский.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(4)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Агинский Бурятский округ</h2>
      <p class="baikal-attraction__text">
        Есть в Забайкалье уникальное место: на многие тысячи километров
        раскинулась бескрайняя степь, коренными жителями которой являются
        Агинские буряты. Они на протяжении всей истории смогли сохранить свою
        идентичность и автономность как от других монгольских племен, так и от
        русских переселенцев. Это, несомненно, привлекает туристов, желающих
        познакомиться с самобытной и очень интересной культурой древнего
        степного народа. Национальный парк «Алханай», заказник «Агинская степь»
        с многочисленными солеными озерами, Агинский дацан – крупнейший
        буддийский храмовый комплекс, долина Онона (урочище Делюн-Болдок),
        которая считается родиной Чингисхана – все это традиционные объекты
        туристского притяжения.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(5)">
      <h2 class="baikal-attraction__title">Заповедные места</h2>
      <p class="baikal-attraction__text">
        Первозданная красота природы Забайкалья представлена разнообразием
        ландшафтов (от горной тундры и тайги до песчаных массивов, схожих с
        настоящей пустыней), чистейшими водами горных озер, необычными горными
        массивами и богатым растительным и животным миром.
      </p>
      <p class="baikal-attraction__text">
        Культовые буддийские сооружения, горные хребты и останцовые скалы,
        родники-аршаны, несущие исцеление и душевный покой, горный воздух – это
        национальный парк Алханай, живописный комплекс в Агинской степи, который
        ежегодно привлекает к себе тысячи туристов и паломников со всей России.
      </p>
      <p class="baikal-attraction__text">
        Также притягательны для путешественников Сохондинский, Даурский
        заповедники и национальный парк «Чикой» - известная среда обитания
        краснокнижных зверей, птиц и растений.
      </p>
    </BaikalAttraction>

    <BaikalAttraction
      :slides="getSlides(6)"
      className="baikal-attraction--reverse"
    >
      <h2 class="baikal-attraction__title">Природно-лечебные ресурсы края</h2>
      <p class="baikal-attraction__text">
        Природа Забайкалья впечатляет не только живописными пейзажами, но и
        количеством лечебных источников. Здесь их больше трехсот: как
        термальных, так и источников холодных магниево-кальциевых вод. По
        составу они близки к Кавказскому нарзану в Кисловодске. Любителей
        лечебного туризма традиционно привлекают бальнеологические курорты Кука,
        Дарасун, Молоковка, Ургучан и грязелечебный курорт озера Угдан. Здесь
        восстанавливают нервную систему, помогают при болезнях органов
        кровообращения и пищеварения, костно-мышечной и мочеполовой системы. А
        сухой ионизированный горный воздух курорта Олентуй – то, что нужно при
        лечении туберкулеза легких.
      </p>
    </BaikalAttraction>

    <BaikalAttraction :slides="getSlides(7)">
      <h2 class="baikal-attraction__title">Природные памятники Забайкалья</h2>
      <p class="baikal-attraction__text">
        Озера Арахлей и Шакшинское в числе самых крупных в России. Они славятся
        богатым подводным миром, живописной прибрежной зоной и условиями для
        круглогодичной рыбалки. Воды озера Арей насыщены кислородом, содержат
        серебро и йод, а грязь со дна озера применяется для лечения кожных
        заболеваний.
      </p>
      <p class="baikal-attraction__text">
        Также туристов привлекают пещеры Хээтэй (самые крупные в Забайкалье),
        скалы Сухотино, где были найдены останки древних людей, животных и
        петроглифы (возрастом 25 тыс.лет).
      </p>
      <p class="baikal-attraction__text">
        В районе горного хребта Кодар соседствуют удивительные природные
        объекты: озеро Ничатка, которое называют Малым Байкалом, ледники Кодара
        и настоящая пустыня - урочище Чарские пески. Это зрелище завораживает!
      </p>
    </BaikalAttraction>
  </div>
</template>

<script>
import BaikalAttraction from "@/components/partials/BaikalAttraction.vue";

export default {
  name: "BaikalAttractionsIrk",
  components: { BaikalAttraction },
  data() {
    return {
      slides: [
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-100.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-101.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-102.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-103.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-104.jpg"),
        },
        {
          sectionId: 1,
          img: require("@/assets/img/attractions/3/baikal-105.jpg"),
        },

        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-200.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-201.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-202.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-203.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-204.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-205.jpg"),
        },
        {
          sectionId: 2,
          img: require("@/assets/img/attractions/3/baikal-206.jpg"),
        },

        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-300.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-301.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-302.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-303.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-304.jpg"),
        },
        {
          sectionId: 3,
          img: require("@/assets/img/attractions/3/baikal-305.jpg"),
        },

        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-400.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-401.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-402.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-403.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-404.jpg"),
        },
        {
          sectionId: 4,
          img: require("@/assets/img/attractions/3/baikal-405.jpg"),
        },

        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-500.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-501.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-502.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-503.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-504.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-505.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-506.jpg"),
        },
        {
          sectionId: 5,
          img: require("@/assets/img/attractions/3/baikal-507.jpg"),
        },

        {
          sectionId: 6,
          img: require("@/assets/img/attractions/3/baikal-600.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/3/baikal-601.jpg"),
        },
        {
          sectionId: 6,
          img: require("@/assets/img/attractions/3/baikal-602.jpg"),
        },

        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-700.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-701.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-702.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-703.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-704.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-705.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-706.jpg"),
        },
        {
          sectionId: 7,
          img: require("@/assets/img/attractions/3/baikal-707.jpg"),
        },
      ],
    };
  },
  methods: {
    getSlides(sectionId) {
      return this.slides.filter((slide) => slide.sectionId === sectionId);
    },
  },
};
</script>
